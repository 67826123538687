<template>
  <div class="row justify-content-center align-items-md-center ">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-md-6 col-12">
              <h4 class="mb-2">
                Paciente: {{ paciente.nome_sobrenome }}
              </h4>
              <h4 class="">Edad:{{ idade }}</h4>
              <h4 class="">Fecha Nascimiento: {{ data_nascimento }}</h4>


            </div>
            <div class="col-md-12 col-12">
              <h4 class="mb-2">
                Sexo:
                {{
                  paciente.sexo_id === 4
                  ? "Femenino"
                  : paciente.sexo_id === 3
                    ? "Masculino"
                    : "No declarado"
                }}
              </h4>
              <h4 class="">RG/CI: {{ paciente.rg }}</h4>
              <h4 class="">Profesión: {{ paciente.profissao }}</h4>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-2">
      <div class="card full-height bg-ligth">
        <div class="card-body">
          <div class="row form-group justify-content-center">
            <div class="form-group col-md-3">
              <label class="col-md-12">{{ $t('DATAINICIO.NOME') }}</label>
              <input required type="date" class="form-control" v-model="filtro.data_inicio" />
            </div>
            <div class="form-group col-md-3">
              <label class="col-md-12">{{ $t('DATAFINAL.NOME') }}</label>
              <input required type="date" class="form-control" v-model="filtro.data_fim" />
            </div>

            <div class="col-md-1 mt-8">
              <div class="">
                <button class="btn btn-success" @click="pesquisar()">
                  <i class="fa fa-search search-icon m-0 p-0"></i>
                </button>
              </div>
              <!-- <div class="ml-1">
                  <button class="btn btn-info" @click="gerarExcel()">
                    <i class="fa fa-print m-0 p-0"></i>
                  </button>
                </div> -->
            </div>
          </div>
          <div class="row" style="align-items: center">
            <b-table :fields="[
              'data',
              'filial',
              'tipo',
              'funcionario',
              'descricao',
              'acoes',
            ]" :items="lista_atendimentos" :per-page="perPage" :current-page="currentPage" id="atendimento-table"
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
              " show-empty empty-text="Ningún registro encontrado!">
              <template #head(acoes)><span></span></template>
              <template #cell(acoes)="{ item }">
                <div class="text-right w-100">
                  <button v-show="lista_permissoes_filial.u_Atendimento" @click="atualizar(item)"
                    class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Editar registro">
                    <i class="far fa-edit text-primary"></i>
                  </button>
                  <button v-show="lista_permissoes_filial.u_Atendimento" @click="trocar_pagina(item.id)"
                    class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Atendimento">
                    <i class="far fa-eye text-success"></i>
                  </button>
                  <button v-show="lista_permissoes_filial.lock_Atendimento" @click="confirm(item)"
                    class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Bloquear/Desbloquear registro">
                    <i class="fas fa-unlock-alt text-danger"></i>
                  </button>
                </div>
              </template>
              <template #cell(tipo)="{ item }"><span></span>
                <span style="font-size: 12px" class="label label-lg label-inline" :class="{
                  'label-light-success': item.tipo === 1,
                  'label-light-info': item.tipo === 2,
                }">
                  {{
                    item.tipo === 1
                    ? "Consulta"
                    : item.tipo === 2
                      ? "Retorno"
                      : ""
                  }}
                </span>
              </template>
            </b-table>
          </div>

          <b-pagination v-model="currentPage" :total-rows="lista_atendimentos.length" :per-page="perPage"
            aria-controls="atendimento-table">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      removido: [],
      currentPage: 1,
      perPage: 10,
      idade: null,
      filtro: {
        data_inicio: null,
        data_fim: null,
        paciente: null,
      },
      data_nascimento: null,
    };
  },
  // watch: {
  //   filtro(){
  //     console.log(3)
  //   }
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Historico del Paciente" }]);
  },
  created() {
    this.listar_atendimentos();
  },
  computed: {
    paciente() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    lista_atendimentos() {
      return this.$store.state.atendimento.lista_atendimentos;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.atendimento.mensagem_alert;
    },
  },
  methods: {
    async calcularIdade() {
      if (this.paciente.data_nascimento != null) {
        var hoje = new Date();
        var dataNascimento = new Date(this.paciente.data_nascimento);
        this.data_nascimento = dataNascimento.toLocaleDateString()
        var idade = hoje.getFullYear() - dataNascimento.getFullYear();
        var meses = hoje.getMonth() - dataNascimento.getMonth();
        if (hoje.getDate() < dataNascimento.getDate()) {
          meses--;
        }
        if (meses < 0 || (meses === 0 && hoje.getDate() < dataNascimento.getDate())) {
          idade--;
          meses = 12 + meses;
        }
        this.idade = ` ${idade} años y ${meses} meses`;
      }
    },
    async listar_atendimentos() {

      const { usuario_id } = this.$route.params;
      console.log(usuario_id);
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      this.$store.dispatch("configUsuarios/listar_usuario", usuario_id);
      await this.$store
        .dispatch("atendimento/listar_atendimentos_paciente", usuario_id)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("configEmpresa/atualizar", "");
        });

      this.calcularIdade()
    },

    atualizar(value) {
      this.$router.push({ name: "createAtendimento" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async pesquisar() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "atendimento/pesquisar_atendimentos",
        this.filtro
      );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara este atendimento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("atendimento/bloquear_atendimento", value)
        .then(this.removido.push(value));
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.fireAlert(this.mensagem_alert);
    },
    async trocar_pagina(id) {
      this.$router.push({
        name: "createOcorrencia",
        params: { atendimento_id: id },
      });
    },
  },
};
</script>
  
<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>